import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | О компании Povix Cleaning Co.
			</title>
			<meta name={"description"} content={"Преображение пространства, обогащение жизни - откройте для себя суть Povix Cleaning Co."} />
			<meta property={"og:title"} content={"О нас | О компании Povix Cleaning Co."} />
			<meta property={"og:description"} content={"Преображение пространства, обогащение жизни - откройте для себя суть Povix Cleaning Co."} />
			<meta property={"og:image"} content={"https://povixvoom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://povixvoom.com/img/3965825.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://povixvoom.com/img/3965825.png"} />
			<meta name={"msapplication-TileImage"} content={"https://povixvoom.com/img/3965825.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" margin="20px 0 0 0">
				О нас
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://povixvoom.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Наша суть
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Povix Cleaning Co. - это не просто уборка, это создание среды, где мир, чистота и гармония сливаются воедино. Наш путь начался с простого убеждения: каждый человек заслуживает того, чтобы жить и работать в помещениях, внушающих спокойствие и радость. Это убеждение лежит в основе нашего стремления предоставлять непревзойденные услуги по уборке, которые не только восстановят вашу обстановку, но и омолодят ваш дух.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Пульс Povix
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					В основе Povix Cleaning Co. лежит наша приверженность к деталям, страсть к чистоте и любовь к простым радостям жизни. Мы верим в преображающую силу чистого пространства, в то, как оно может поднять настроение, повысить продуктивность и привнести чувство спокойствия в ваш суматошный день. Наша команда воплощает эту философию, с энтузиазмом и опытом выполняя каждую задачу, гарантируя, что, уходя, мы оставляем после себя пространство, дышащее позитивом.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://povixvoom.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				sm-font="--headline3"
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--headline2"
				lg-text-align="center"
				md-text-align="left"
			>
				Почему наша сущность имеет значение

			</Text>
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://povixvoom.com/img/8.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://povixvoom.com/img/9.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					- Стремление к совершенству: Наша неизменная приверженность качеству и тщательное внимание к деталям означает, что ваше помещение находится в руках специалистов, которым небезразличен результат.
					<br />
					- Экологически ответственная уборка: Используя экологически чистые продукты и методы, мы защищаем не только ваше помещение, но и планету, обеспечивая чистоту, которая сопровождается душевным спокойствием.

				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://povixvoom.com/img/10.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					- Индивидуальные решения по уборке: Понимая, что нет двух одинаковых помещений, мы предлагаем индивидуальные планы уборки, учитывающие ваши конкретные потребности, предпочтения и графики.
					<br />
					- Надежность и доверие: Построенные на фундаменте доверия и надежности, мы гордимся своим профессионализмом, гарантируя, что к вашему помещению будут относиться с максимальным уважением и конфиденциальностью.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});